<script setup lang="ts">
import c3api from "@/c3api";
import { defineProps, onBeforeMount, ref, watch, reactive, onBeforeUpdate, type Ref, type EmitsOptions } from "vue";
import useTimezones from "@/hooks/useTimezone";


interface WarehouseData {
  name: string | null;
  nickname: string | null;
  code: string | null;
  color: string | null;
  address: any;
  damaged_bin_id: null | undefined;
  tz_identifier: null | undefined;
}

interface Emits {
  (event: 'update:showDialog', value: boolean): void;
}

const {timezones, fetchTimezones} = useTimezones();
const emit = defineEmits<Emits>();
const picker: Ref<string> = ref("#000000");
const showColorPicker: Ref<boolean> = ref(false);
const damagedBins = ref([]);
const warehouseData: WarehouseData = reactive({
  name: null,
  nickname: null,
  code: null,
  color: null,
  address: {
    street: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  },
  damaged_bin_id: null,
  tz_identifier: null
});

const props = defineProps<{
  warehouse: any | null;
  onSubmit: (data: WarehouseData) => void;
  showDialog: boolean;
}>();

const dialog: Ref<boolean> = ref(props.showDialog);

onBeforeMount(() => {
  fetchTimezones();
});

onBeforeUpdate(async () => {
  if (props.warehouse) {
    picker.value = props.warehouse.color;
    warehouseData.name = props.warehouse.name;
    warehouseData.nickname = props.warehouse.nickname;
    warehouseData.code = props.warehouse.code;
    warehouseData.color = props.warehouse.color;
    warehouseData.address.street = props.warehouse.address_entry.street;
    warehouseData.address.city = props.warehouse.address_entry.city;
    warehouseData.address.state = props.warehouse.address_entry.state;
    warehouseData.address.zip = props.warehouse.address_entry.zip;
    warehouseData.address.country = props.warehouse.address_entry.country;
    warehouseData.tz_identifier = props.warehouse.tz_identifier;
    warehouseData.damaged_bin_id = props.warehouse.damaged_bin_id;
    await fetchDamageBins(props.warehouse.id);
  }
});

const closeDialog = () => {
  dialog.value = false;
  props.onCancel();
  emit("update:showDialog", dialog.value);
};

watch(dialog, (newVal) => {
  if (newVal != props.showDialog) {
    emit("update:showDialog", newVal);
  }
});

const createWarehouse = () => {
  warehouseData.color = picker.value;
  props.onSubmit(warehouseData);
  closeDialog();
};

watch(
  () => props.showDialog,
  (newVal) => {
    if (newVal) {
      // Reset warehouseData to default values here
      resetWarehouseData();
      dialog.value = newVal;
    }
  }
);

const resetWarehouseData = () => {
  warehouseData.name = null;
  warehouseData.nickname = null;
  warehouseData.code = null;
  warehouseData.color = null;
  warehouseData.address.street = null;
  warehouseData.address.city = null;
  warehouseData.address.state = null;
  warehouseData.address.zip = null;
  warehouseData.address.country = null;
};

const fetchDamageBins = async (warehouseId) => {
  const response = await c3api.get(`warehouses/${warehouseId}/bins`);
  damagedBins.value = response.data.data
};
</script>

<template>
  <v-dialog width="500" v-model="dialog">
    <template v-slot:default="{ isActive }">
      <v-card :title="props.warehouse ? 'Edit Warehouse' : 'New Warehouse'">
        <v-card-text>
          <v-text-field label="Name" v-model="warehouseData.name"></v-text-field>
          <v-text-field label="Nickname" v-model="warehouseData.nickname"></v-text-field>
          <v-text-field label="Code" v-model="warehouseData.code"></v-text-field>
          <!-- Color Picker Menu -->
          <v-menu v-model="showColorPicker" :close-on-content-click="false" open-on-hover>
            <template v-slot:activator="{ props }">
              <v-text-field
                v-bind="props"
                label="Color"
                v-model="picker"
                readonly
              ></v-text-field>
            </template>
            <v-color-picker v-model="picker"></v-color-picker>
          </v-menu>
          <div class="text-h7 my-4">Warehouse Address</div>
          <v-text-field
            label="Street"
            v-model="warehouseData.address.street"
          ></v-text-field>
          <v-text-field label="City" v-model="warehouseData.address.city"></v-text-field>
          <v-text-field
            label="State"
            v-model="warehouseData.address.state"
          ></v-text-field>
          <v-text-field label="Zip" v-model="warehouseData.address.zip"></v-text-field>
          <v-text-field
            label="Country"
            v-model="warehouseData.address.country"
          ></v-text-field>

          <div class="text-h7 my-4">Timezone</div>
          <v-autocomplete
            v-model="warehouseData.tz_identifier"
            :items = "timezones"
            label="Timezone"
          ></v-autocomplete>

          <div class="text-h7 my-4">Damaged Bin</div>
          <v-autocomplete
            v-model="warehouseData.damaged_bin_id"
            :items="damagedBins"
            item-title="name"
            item-value="id"
            label="Damaged Bin"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Cancel" @click="closeDialog"></v-btn>
          <v-btn color="primary" @click="createWarehouse">{{
            warehouse ? "Save" : "Create"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
