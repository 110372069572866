<script setup>
import { onMounted, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router/auto";
import { loadingStore as loading, userStore } from "../../stores";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { shortDateWithTimeTz } from "../../utils/dateUtils";
import useWarehouses from "../../hooks/useWarehouses";
import WarehouseForm from "../../components/warehouse/WarehouseForm.vue";
import debounce from "lodash/debounce";
import LoadingProgess from "../../components/LoadingProgress.vue";

const route = useRoute();
const router = useRouter();
const {
  updatePage,
  warehouses,
  fetchWarehouses,
  createWarehouse,
  editWarehouse,
  deleteWarehouse,
  warehousesLoading,
} = useWarehouses();

const localState = ref({
  page: parseInt(route.query.page) || 1,
  searchText: "",
  filters: [],
  sort: "id",
});

const dialogState = ref({
  showCreate: false,
  showEdit: false,
  editingWarehouse: null,
});

onMounted(() => {
  setPageTitle("Manage Warehouses");
  debouncedWarehousesReq(localState.value);
});

watch(localState, () => debouncedWarehousesReq(localState.value), { deep: true });

const toggleDialog = (type, warehouse = null) => {
  if (type == "edit") {
    dialogState.value.editingWarehouse = warehouse;
    dialogState.value.showEdit = !dialogState.value.showEdit;
  } else {
    dialogState.value.showCreate = !dialogState.value.showCreate;
  }
};

const warehouseActionSuccess = async (warehouseParams, isEdit = false) => {
  if (isEdit) {
    await editWarehouse(dialogState.value.editingWarehouse.id, warehouseParams).then(
      () => {
        dialogState.value.editingWarehouse = null;
        dialogState.value.showEdit = false;
      }
    );
  } else {
    await createWarehouse(warehouseParams).then((response) => {
      if (response.status == 201) {
        dialogState.value.showCreate = false;
      }
    });
  }
  debouncedWarehousesReq(localState.value);
};

const deleteWarehouseAction = async (warehouse) => {
  await deleteWarehouse(warehouse);
  debouncedWarehousesReq(localState.value);
};

const debouncedWarehousesReq = debounce(async (state) => {
  warehouses.value = null;
  await fetchWarehouses(state);
}, 500);

watch(
  () => localState.value.searchText,
  (newVal) => {
    localState.value.filters = localState.value.filters.filter(
      (filter) => filter.column !== "name"
    );
    if (newVal) {
      localState.value.filters.push({
        condition: "like",
        column: "name",
        value: newVal, // Assuming your backend uses SQL 'LIKE' syntax, adjust as needed
      });
    }
    localState.value.page = 1; // Reset to first page on new search
    debouncedWarehousesReq(localState.value); // Call the debounced fetch function
  }
);
</script>

<template>
  <div>
    <v-card-title class="d-flex justify-space-between"
      ><span>{{ pageTitle }}</span
      ><v-btn
        color="primary"
        @click="toggleDialog('create')"
        v-if="userStore.hasPermissionTo('create_warehouse')"
        >New Warehouse</v-btn
      >
    </v-card-title>
    <v-card-subtitle>Use this section to manage warehouses. </v-card-subtitle>
    <v-card flat class="mx-4 px-4 mt-4">
      <div class="mt-4">
        <v-text-field
          v-model="localState.searchText"
          variant="outlined"
          color="indigo-lighten-2"
          prepend-inner-icon="mdi-text-box-search-outline"
          label="Search by name"
          flat
        ></v-text-field>
        <v-table>
          <thead>
            <tr>
              <th class="text-left">Warehouse</th>
              <th class="text-left">Address</th>
              <th class="text-left">Created At</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>

          <tbody v-if="!warehousesLoading && warehouses && warehouses.data.length == 0">
            <tr>
              <td colspan="4" class="text-center">No warehouses found.</td>
            </tr>
          </tbody>

          <tbody
            v-if="!loading.isLoading && warehouses && warehouses.data.length > 0"
            class=""
          >
            <tr
              @dblclick="router.push(`/warehouses/${warehouse.id}`)"
              v-for="warehouse in warehouses.data"
              :key="warehouse.id"
              style="cursor: pointer"
            >
              <td>
                <v-row class="py-2">
                  <v-col cols="auto" class="d-flex align-center">
                    {{ warehouse.id }}</v-col
                  >
                  <v-col>
                    <div class="d-flex align-center w-100">
                      {{ warehouse.name }}
                    </div>
                    <span class="text-indigo-lighten-2 text-caption"
                      >{{ warehouse.nickname }} (Code: {{ warehouse.code }})</span
                    >
                  </v-col>
                </v-row>
              </td>
              <td>{{ warehouse.address_entry?.full_address }}</td>
              <td>{{ shortDateWithTimeTz(warehouse.updated_at) }}</td>
              <td>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      density="comfortable"
                      icon="mdi-dots-horizontal"
                      elevation="0"
                      v-bind="props"
                    ></v-btn>
                  </template>

                  <v-list>
                    <v-list-item :to="`/warehouses/${warehouse.id}`">
                      <v-list-item-title>View</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="`/warehouses/${warehouse.id}/dashboard`">
                      <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="toggleDialog('edit', warehouse)"
                      v-if="userStore.hasPermissionTo('update_warehouse')"
                    >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="deleteWarehouseAction(warehouse)"
                      v-if="userStore.hasPermissionTo('delete_warehouse')"
                    >
                      <v-list-item-title class="text-error">Delete</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item>
                      <v-list-item-title class="text-error">Delete</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-table>
        <LoadingProgess v-if="warehousesLoading" />
      </div>
    </v-card>
    <div class="text-center mt-4" v-if="warehouses && warehouses.total_pages > 1">
      <v-pagination
        active-color="primary"
        color="grey-darken-1"
        v-model="localState.page"
        :length="warehouses.total_pages"
        @update:modelValue="updatePage"
        rounded="circle"
      ></v-pagination>
    </div>
  </div>
  <WarehouseForm
    :warehouse="dialogState.editingWarehouse"
    :showDialog="dialogState.showEdit"
    @update:showDialog="(val) => (dialogState.showEdit = val)"
    :onSubmit="(params) => warehouseActionSuccess(params, true)"
  />

  <WarehouseForm
    :warehouse="null"
    :showDialog="dialogState.showCreate"
    @update:showDialog="(val) => (dialogState.showCreate = val)"
    :onSubmit="warehouseActionSuccess"
  />
</template>
